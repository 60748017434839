import $ from 'jquery';

$(document).on('change', '.js--customer-prefill', function() {
  var id = $(this).val();

  if(id) {
    $.get('/customers/prefills/' + id, function(data, status) {
      console.log('Prefills loaded');
    }).fail(function() {
      alert('Something went wrong, please try again');
    });
  } else {
    document.getElementById('user_customer_attributes_name').value = '';
    document.getElementById('user_customer_attributes_address_line_1').value = '';
    document.getElementById('user_customer_attributes_address_line_2').value = '';
    document.getElementById('user_customer_attributes_city').value = '';
    document.getElementById('user_customer_attributes_postcode').value = '';
  }
});
