import $ from 'jquery';

window.jsQuantityToggles = function() {
  $('.js--quantity').each(function() {
    var $parent = $(this).parent();

    if(!$parent.hasClass('js--quantity-initialised')) {
      $parent.append('<input type="button" value="+" class="js--quantity-toggle js--quantity-increase" />');
      $parent.prepend('<input type="button" value="-" class="js--quantity-toggle js--quantity-decrease" />');
      $parent.addClass('js--quantity-initialised');
    }
  });
}

$(document).on('click', '.js--quantity-toggle', function() {
  var $quantity = $(this).parent().find('.js--quantity');
  var val = parseInt($quantity.val());

  if($(this).hasClass('js--quantity-increase')) {
    val += 1;
    $quantity.val(val);
  } else if($(this).hasClass('js--quantity-decrease')) {
    val -= 1;

    if(val > 0) {
      $quantity.val(val);
    }
  }

  if($quantity.hasClass('js--quantity-submit')) {
    var form = $quantity.data('form');
    $('#' + form).trigger('click');
  }
});

$(document).on('change', '.js--quantity-submit', function() {
  var form = $(this).data('form');
  $('#' + form).trigger('click');
});

jsQuantityToggles();

document.addEventListener("product-variant-loaded", function(event) {
  // event.container
  jsQuantityToggles();
});
