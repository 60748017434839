import $ from 'jquery';

/*
var typingToggleTimer;
var typingTimeout = 250;
*/

$(document).on('click', '.js--product-searches-sort', function(event) {
  var sort = $(this).data('sort');
  $('#products_searches_form_sort').val(sort);
  $('#products_searches_form_sort').parent('form').trigger('submit');
  event.preventDefault();
});

$(document).on('submit', '.js--product-searches-query-form', function(e) {
  e.preventDefault();
  var query = $('.js--product-searches-query').val();
  $('#products_searches_form_query').val(query);
  $('.product-category-searches-aside-form').trigger('submit');
});

/*
$(document).on('input', '.js--product-searches-query', function() {
  clearTimeout(typingToggleTimer);
  typingToggleTimer = setTimeout(productSearchQuery, typingTimeout, query);
});

function productSearchQuery(query) {
  $('#products_searches_form_query').val(query);
}
*/
