import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// Passive event listeners

jQuery.event.special.touchstart = {
  setup: function( _, ns, handle ) {
    this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
  }
};
jQuery.event.special.touchmove = {
  setup: function( _, ns, handle ) {
    this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
  }
};

require('../src/prefills/customer');
require('../src/forms');
require('../src/slick');
require('../src/youtube');
require('../src/utilities');
require('../src/products');
require('../src/pagination');
require('../src/carts');
require('../src/taptapgo');
require('../src/ga-events');
require('../src/google-maps/init')
require('../src/match-height')

// window.onpopstate = function(event) {
//   if(event.state && event.state.hasOwnProperty('page')) {
//     var pageId = 'page-' + event.state.page;

//     console.log(pageId);
//     console.log(document.getElementById(pageId));

//     if(document.getElementById(pageId)) {
//       window.location.href = '#' + pageId;
//     }
//   }
// }
