// @see
//   https://stackoverflow.com/a/9709425

import $ from 'jquery';

// the selector will match all input controls of type :checkbox
// and attach a click event handler
$(document).on('click', '.js--grouped-checkbox', function() {
  var $box = $(this);
  if ($box.is(":checked")) {
    var group = "input:checkbox[data-group='" + $box.attr("data-group") + "']";
    $(group).prop("checked", false);
    $box.prop("checked", true);
  } else {
    $box.prop("checked", false);
  }
});

function disableInactiveValues() {
  $('.js--products-variant-filter').each(function() {
    var optionValue = $(this).find(':selected').val();
    if(!optionValue) {
      var option = $(this).data('option');
      var availableOptions = [];

      $('[data-' + option + ']:visible').each(function() {
          availableOptions.push(parseInt($(this).data(option)));
      });


      $(this).find('option').each(function() {
        var optionValue = parseInt($(this).val());

        if(optionValue && !availableOptions.includes(optionValue)) {
          $(this).attr('disabled', 'disabled');
        } else if(isNaN(optionValue) || availableOptions.includes(optionValue)) {
          $(this).removeAttr('disabled');
        }
      });
    }
  });
}

function optionValueFilter() {
  var optionValues = [];
  var optionValueSelector = '';

  $('.js--products-variant-filter').find(':selected').each(function() {
    var optionValue = $(this).val();
    if(optionValue) {
      var option = $(this).parent('select').data('option');
      optionValueSelector += '[data-' + option + '="' + optionValue + '"]';
      optionValues.push(optionValue);
    }
  });

  var $productImage = $('.product-content-image__image');

  if($productImage.length == 1) {
    var originalSrc, productImageSrc = $productImage.attr('src');

    if(!$productImage.data('original-src')) {
      $productImage.data('original-src', productImageSrc);
    } else {
      originalSrc = $productImage.data('original-src')
    }
  }

  if(optionValues.length == 0) {
    $('.js--products-variant-filter-clear').show();
    $('.js--products-variant-row').show();
    $('.js--products-variant-filter').find('option').removeAttr('disabled');

    if($productImage.length == 1) {
      $productImage.attr('src', originalSrc);
    }
  } else {
    $('.js--products-variant-filter-clear').show();
    $('.js--products-variant-row').hide();
    $(optionValueSelector).show();
    disableInactiveValues();

    if($productImage.length == 1) {
      var $visibleVariantImage = $('.product-variants-table-row__variant-image:visible');
      if($visibleVariantImage.length > 0) {
        $productImage.attr('src', $visibleVariantImage.data('show-image'));
      } else {
        $productImage.attr('src', originalSrc);
      }
    }
  }
}

$(document).on('click', '.js--products-variant-filter-clear', function() {
  $('.js--products-variant-filter').val('').trigger('change');
  $(this).hide();
});

$(document).on('change', '.js--products-variant-filter', function() {
  optionValueFilter();
});
