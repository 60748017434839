/*
This gets hidden by hubspot

let supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

if (!supportsTouch) {
  var headerHeight;

  headerHeight = document.querySelector('.primary-header-wrap').offsetHeight;
  var scrollButton = document.querySelector('.primary-footer-scroll-button');

  window.addEventListener('scroll', function(event) {
    if(window.pageYOffset > headerHeight) {
      scrollButton.style = 'opacity: 1';
    } else {
      scrollButton.style = 'opacity: 0';
    }
  });
}
*/

$(document).on('click', '.js--form-add-to-cart', function(event) {
    var clickableElement = $(this).data('element');
    console.log(document.getElementById(clickableElement));
    if(document.getElementById(clickableElement)) {
      document.getElementById(clickableElement).click();
    }
    event.preventDefault();
});

$(document).on('click', '.js--form-add-to-quote', function(event) {
    var clickableElement = $(this).data('element');
    console.log(clickableElement + '-quote-request');
    document.getElementById(clickableElement + '-quote-request').value = true;
    if(document.getElementById(clickableElement)) {
      document.getElementById(clickableElement).click();
    }
    event.preventDefault();
});

divs = document.querySelectorAll('.js--form-submit-on-change');

for (i = 0; i < divs.length; ++i) {
  divs[i].addEventListener('change', function (event) {
    var elem = event.target;
    elem.closest('form').querySelector('input[type=submit]').click();
  }, false);
}

// @see https://stackoverflow.com/a/27232658
function webpSupport() {
  var elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
  } else {
    return false;
 }
}

if(!webpSupport()) {
  console.log('webp support unavailable fallback');
  var elements = document.querySelectorAll('[data-webp-fallback]');
  for (let i = 0, n = elements.length; i < n; i++) {
    elements[i].setAttribute('src', elements[i].dataset.webpFallback);
  };
}
