import GoogleMapsConstructor from './constructor';
import GoogleMapsRender from './render';

var mapCanvas = '.js--map-canvas';

window.googleMapCallback = function () {
  new GoogleMapsRender({ mapCanvas: mapCanvas, google: google }).init();
};

var initializer;

if (typeof Turbolinks == 'undefined') {
  initializer = 'DOMContentLoaded'
} else {
  initializer = 'turbolinks:load';
}

document.addEventListener(
  initializer,
  function () { new GoogleMapsConstructor({ mapCanvas: mapCanvas }).init() }
);
