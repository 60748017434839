var gaEvent = function() {
  var sendEvent = function(event, parameters) {
    // Analytics
    if (typeof ga === 'function') {
      ga('send', {
        hitType: 'event',
        eventCategory: parameters.category, // required
        eventAction: event, // required
        eventLabel: parameters.label,
        // eventValue: eventValue,
        hitCallback: function() {
          console.dir(event);
          console.dir(parameters);
        }
      });
    }
  }

  var runEvent = function(element) {
    var dataAttrs = element.dataset;

    var eventData = {
      category: dataAttrs['eventCategory'],
      label: dataAttrs['eventLabel']
    };

    sendEvent(dataAttrs['eventAction'], eventData)
  }

  var matchesCheck = function () {
    // IE support: https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector;
    }
  }

  var addEvent = function (parent, evt, selector, handler) {
    parent.addEventListener(evt, function (event) {
      if (event.target.matches(selector + ', ' + selector + ' *')) {
        handler.apply(event.target.closest(selector), arguments);
      }
    }, false);
  }

  var init = function (selector) {
    matchesCheck();
    addEvent(document, 'click', selector, function (e) {
      runEvent(this);
    });
  }

  this.init = function (selector) {
    init(selector);
  }

  this.sendEvent = function (event, parameters) {
    sendEvent(event, parameters);
  }
}

// Initialize
window.gaEvents = new gaEvent();

// @note Bind to elements
window.gaEvents.init('.js--ga-event');

// @note Send via JS
// window.gaEvents.sendEvent('event', { 'param_1': 'value', 'param_2': 'value' });
