require('slick-carousel');
import $ from 'jquery';

$('.js--banners').slick({
  nextArrow: '.js--banners-next',
  prevArrow: '.js--banners-prev',
  dots: false,
  autoplay: true,
  autoplaySpeed: 3000
});

$('.js--products-images').slick({
  nextArrow: '.js--products-images-next',
  prevArrow: '.js--products-images-prev',
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  arrows: true,
  fade: true,
  asNavFor: '.js--products-images-navigation'
});

$('.js--products-images-navigation').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  centerMode: true,
  focusOnSelect: true,
  asNavFor: '.js--products-images'
});

$('.js--horizontal-case-studies-contents').slick({
  nextArrow: '.js--horizontal-case-studies-next',
  prevArrow: '.js--horizontal-case-studies-prev',
  asNavFor: '.js--horizontal-case-studies-images',
  dotsClass: 'js--horizontal-case-studies-dots',
  dots: true,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3000
});

$('.js--horizontal-case-studies-images').slick({
  dots: false,
  arrows: false,
  asNavFor: '.js--horizontal-case-studies-contents'
});

$('.js--horizontal-team-members').slick({
  nextArrow: '.js--horizontal-team-members-next',
  prevArrow: '.js--horizontal-team-members-prev',
  dots: false,
  autoplay: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
  ]
});

$('.js--case-studies-images').slick({
  nextArrow: '.js--case-studies-images-next',
  prevArrow: '.js--case-studies-images-prev',
  dotsClass: 'js--case-studies-images-dots',
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  fade: true
});

$('.js--processes').slick({
  dots: false,
  arrows: false,
  autoplay: false,
  fade: true
});

$('.js--related-products').slick({
  dotsClass: 'js--related-products-dots',
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: false,
  dots: true,
  arrows: false
});

$('.js--processes').on('beforeChange', function(event, slick, currentSlide,nextSlide){
  var nextSlideNumber = nextSlide + 1;
  $('.processes-list__item--active').removeClass('processes-list__item--active');
  $('.processes-list__item[data-slide="process-' + nextSlideNumber + '"]').addClass('processes-list__item--active');
});

$(document).on('click', '.js--processes-navigation', function(event) {
  var slide = $(this).attr('href').match(/\d+/)[0] - 1;
  $('.js--processes').slick('slickGoTo', slide);
  event.preventDefault();
});
