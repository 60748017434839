// @see https://stackoverflow.com/a/7557433
var visible = false;

function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();

  return rect.bottom > 0 &&
  rect.right > 0 &&
  rect.left < (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */ &&
  rect.top < (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */;
}

function handler() {
  var el = document.querySelector('.pagination-navigation__button');

  if(el) {
    currentlyVisible = isElementInViewport(el);
    // console.log(currentlyVisible);

    if (currentlyVisible != visible) {
      visible = true;

      if(!el.dataset.disabled) {
        el.click();
      }

      el.dataset.disabled = true;
    }
  }
}

if (window.addEventListener) {
  addEventListener('scroll', handler, false);
  addEventListener('resize', handler, false);
} else if (window.attachEvent)  {
  attachEvent('onscroll', handler);
  attachEvent('onresize', handler);
}
